import { Controller } from '@hotwired/stimulus'
import copyToClipboard from 'common/utils/copy'

export default class extends Controller {
  static targets = ['cancelMembershipModalTemplate', 'endMembershipModalTemplate', 'managePasswordsModalTemplate', 'deleteForm']

  showCancelConfirmModal() {
    this.createModal(this.cancelMembershipModalTemplateTarget.innerHTML, 'cancel_membership_modal', 'small', true)
  }

  showEndConfirmModal() {
    this.createModal(this.endMembershipModalTemplateTarget.innerHTML, 'end_membership_modal', 'small', true)
  }

  showManagePasswordsModal() {
    this.createModal(this.managePasswordsModalTemplateTarget.innerHTML, 'manage_passwords_modal', 'small', true)
  }

  copyPasswordResetLink(e) {
    e.preventDefault()

    const container = document.getElementById('flash_messages')

    const message = document.createElement('div')
    message.classList.add('u-flash', 'u-flash-success')
    message.setAttribute('data-controller', 'alert')
    message.setAttribute('data-alert-timeout-value', '5000')
    message.setAttribute('data-action', 'click->alert#close')
    message.setAttribute('data-alert-target', 'alert')

    message.innerText = 'Password reset link has been copied to your clipboard.'

    const { currentTarget } = e

    currentTarget.setAttribute('loading', 'true')

    fetch(currentTarget.dataset.url)
      .then(response => response.json())
      .then(json => {
        currentTarget.removeAttribute('loading')
        container.appendChild(message)
        copyToClipboard(json.link)
      })
  }

  sendPasswordResetLink(e) {
    e.preventDefault()

    const container = document.getElementById('flash_messages')

    const message = document.createElement('div')
    message.classList.add('u-flash', 'u-flash-success')
    message.setAttribute('data-controller', 'alert')
    message.setAttribute('data-alert-timeout-value', '5000')
    message.setAttribute('data-action', 'click->alert#close')
    message.setAttribute('data-alert-target', 'alert')

    message.innerText = 'Password reset link has been sent.'

    const { currentTarget } = e

    currentTarget.setAttribute('loading', 'true')

    fetch(currentTarget.dataset.url)
      .then(response => response.json())
      .then(() => {
        currentTarget.removeAttribute('loading')
        container.appendChild(message)
      })
  }

  deleteUser(e) {
    e.preventDefault()

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this user?')) {
      this.deleteFormTarget.requestSubmit()
    }
  }

  createModal(content, id, size = 'large', withCloseIcon = false) {
    document.getElementById(id)?.remove()

    const modal = document.createElement('ds-modal')
    modal.setAttribute('size', size)
    modal.setAttribute('position', 'center')
    modal.setAttribute('closable', 'true')
    modal.setAttribute('show-on-mount', 'true')
    modal.setAttribute('id', id)

    if (withCloseIcon) {
      modal.setAttribute('with-close-icon', 'true')
    }

    modal.innerHTML = content

    this.closestShowController.element.appendChild(modal)

    return modal
  }

  closeModal(e) {
    e.currentTarget.closest('ds-modal').close()
  }

  get closestShowController() {
    const container = document.getElementById('user_general_info')
    return this.application.getControllerForElementAndIdentifier(container, 'community--people--show')
  }
}
