import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['parent']

  static values = {
    filter: String,
  }

  connect() {
    window.addEventListener('message', this.handleMessage.bind(this))
  }

  disconnect() {
    window.removeEventListener('message', this.handleMessage.bind(this))
  }

  handleMessage(event) {
    if (!event.origin.startsWith('https://omni.uscreen.tv')) {
      return
    }

    const { data } = event
    console.log('Omni controller handleMessage', data)
    if (data?.payload?.name === 'dashboard-link-click' && data?.payload?.type === 'click') {
      const values = data.payload.data.split('_')
      const newId = values[0]
      const newDashboardId = values[1]

      this.openTab(newId, newDashboardId)
    }

    if (data.name === 'size' && this.hasParentTarget) {
      this.setHeight(data.payload.height)
    }

    if (data.name === 'dashboard:tile-download' || data.name === 'dashboard:download') {
      this.sendEvent(data.name, data.payload)
    }
    if (data.name === 'dashboard:filters') {
      this.filterValue = null
      this.filterValue = Object.values(data.payload)
        .map(filter => filter.asJsonUrlSearchParam)
        .filter(param => param) // Remove any undefined or null values
        .join('&')
    }
  }

  setHeight(height) {
    this.parentTarget.style.height = `${height}px`
  }

  openTab(newId, newDashboardId) {
    const currentUrl = new URL(window.location.href)
    const pathParts = currentUrl.pathname.split('/')

    // if path has only :id, replace the last part with the new id
    if (pathParts.length >= 2 && pathParts[pathParts.length - 2] === 'embedded') {
      pathParts.splice(-1, 1, newId)
      if (newDashboardId) {
        pathParts.push(newDashboardId)
      }
      // if path contains :dashboard_id, replace :id and :dashboard_id with the new id and new dashboard id
    } else if (pathParts.length >= 3 && pathParts[pathParts.length - 3] === 'embedded') {
      pathParts.splice(-2, 2, newId)
      if (newDashboardId) {
        pathParts.push(newDashboardId)
      }
    }

    currentUrl.pathname = pathParts.join('/')
    if (this.filterValue) {
      currentUrl.searchParams.set('filter', this.filterValue)
    } else {
      currentUrl.searchParams.delete('filter')
    }
    window.location.href = currentUrl.toString()
  }

  sendEvent(name, payload) {
    window.posthog.capture(name, payload)
  }
}
