import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['currencySelect', 'priceInput']

  static values = {
    currency: String,
    currenciesWithoutDecimals: Array
  }

  connect() {
    this.updateAllInputs()
  }

  updateAllInputs() {
    const selectedCurrency = this.currencySelectTarget.value[0] || this.currencyValue
    const hasNoDecimals = this.currenciesWithoutDecimalsValue.includes(selectedCurrency)
    const step = hasNoDecimals ? 1 : 0.01
    const minValue = hasNoDecimals ? 1 : 0.5

    this.priceInputTargets.forEach(input => {
      input.step = step
      input.min = minValue
      let value = parseFloat(input.value)
      if (isNaN(value) || value < minValue) {
        value = minValue
      }
      input.value = this.formatValue(value, step)
    })
  }

  formatValue(value, step) {
    if (step === 1) {
      return Math.round(value)
    }

    return value.toFixed(2)
  }
}
